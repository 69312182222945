<template>
  <Block :theme="theme" title="科室制定情况">
    <chart-bar @clickBar="clickBar" :colors="colors" :types="types" class="chart" :dataList="dataList"></chart-bar>
  </Block>
</template>

<script>
import ChartBar from '../cmp/bar'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartBar
  },
  props: {
    normalData: {
      type: Array
    },
    appendData: {
      type: Array
    },
    theme: {
      type: String
    },
    haveAppend: {
      type: Boolean,
      default: true
    },
    haveNormal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickBar (parm) {
      let budgetApplyOrgId = this.dataList[parm.dataIndex].id
      let budgetYearType = this.types[parm.seriesIndex] ? this.$store.getters.budgetYearTypeList.find(v => v.label === this.types[parm.seriesIndex]).key : null
      let key = 'budgetApplyOrgId' + budgetApplyOrgId + 'budgetYearType' + budgetYearType
      this.$emit('chooseData', {
        __key: key,
        budgetApplyOrgId: this.dataList[parm.dataIndex].id,
        budgetYearType: this.types[parm.seriesIndex] ? this.$store.getters.budgetYearTypeList.find(v => v.label === this.types[parm.seriesIndex]).key : null
      })
    }
  },
  computed: {
    colors () {
      let allColors = this.theme === 'orange' ? ['#FF9D6B', '#FFE369'] : ['#3776ED', '#37CBED']
      let colors = []
      if (this.haveNormal) {
        colors.push(allColors[0])
      }
      if (this.haveAppend) {
        colors.push(allColors[1])
      }
      return colors
    },
    types () {
      let all = this.$store.getters.budgetYearTypeList.map(v => v.label)
      let datas = []
      if (this.haveNormal) {
        datas.push(all[0])
      }
      if (this.haveAppend) {
        datas.push(all[1])
      }
      return datas
    },
    dataList () {
      let orgMap = {}
      if (this.normalData) {
        this.normalData.forEach(v => {
          if (!orgMap[v.budgetApplyOrgId]) {
            orgMap[v.budgetApplyOrgId] = {
              id: v.budgetApplyOrgId,
              name: v.budgetApplyOrgName,
              normal: v.total || 0
            }
          } else {
            orgMap[v.budgetApplyOrgId].normal += v.total
          }
        })
      }
      if (this.appendData) {
        this.appendData.forEach(v => {
          if (!orgMap[v.budgetApplyOrgId]) {
            orgMap[v.budgetApplyOrgId] = {
              id: v.budgetApplyOrgId,
              name: v.budgetApplyOrgName,
              append: v.total || 0
            }
          } else {
            orgMap[v.budgetApplyOrgId].append = (orgMap[v.budgetApplyOrgId].append || 0) + v.total
          }
        })
      }
      let data = Object.keys(orgMap).map(key => {
        let item = orgMap[key]
        item.data = []
        if (this.haveNormal) {
          item.data.push(item.normal || 0)
        }
        if (this.haveAppend) {
          item.data.push(item.append || 0)
        }
        return item
      })
      return data
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
